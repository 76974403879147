.line {
  border: 1px solid #ffffff;
  width: 100%;
  opacity: 30%;
  margin-bottom: 28px;
}

.footer {
  padding: 100px 0 20px 0;
  background: #121212;
}

.listContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
}
