.section {
  position: relative;
  width: 100%;
  padding: 100px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
}

/* Вариант для основного фона */
.primary {
  background-color: var(--primary-bg); /* Бежевый фон */
  color: var(--text-color); /* Темный текст */
}

/* Вариант для второстепенного фона */
.secondary {
  background-color: var(--white-color); /* Светло-серый фон */
  color: var(--text-color); /* Темный текст */
}

/* Вариант для акцентной секции */
.accent {
  background-color: var(--accent-bg); /* Темно-бирюзовый фон */
  color: var(--white-color); /* Белый текст */
}

.darken {
  background-color: black;
}

.white {
  background-color: #fff;
}

/* Для секций с карточками */
.cards {
  padding: 80px 20px;
  background: linear-gradient(
    135deg,
    #ffebee 0%,
    #ffc107 100%
  ); /* Градиентный фон */
  color: var(--text-color);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
