.navItem {
  position: relative;
  padding: 4px 24px;

  .navItemsList {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: -2px 4px 15px 0px #00074526;
    z-index: 1000;
    min-width: 206px;

    li {
      position: relative;
      padding: 10px 12px;
      &.active {
        background-color: #ffedd6 !important;
      }

      a {
        padding: 12px 16px;
        color: #333333;
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding: 0;
      }
    }

    .navItem {
      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 12px 16px;
        font-weight: 500;
        font-size: 16px;
        background-color: #ffffff;
        color: #333333;
        border: none;

        &:hover {
          background-color: #f4f4f4;
        }
        &.active {
          background-color: #ffedd6;
        }
      }

      ul {
        top: 0;
        left: 100%;
        margin-left: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }
    }
  }

  svg {
    width: 14px;
    height: 14px;
    transition: transform 0.3s ease;

    &.rotate-180 {
      transform: rotate(180deg);
    }
  }
}
