.navList {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}
.navListInner {
  max-width: 192px;
}

.nestedList {
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
  opacity: 0;
}

.open {
  max-height: 500px; // примерное максимальное значение для элементов
  opacity: 1;
}
